<template>
  <Form
    :data="{
      currentStep,
      stepItems,
      breadcrumbItems,
      isLoading,
    }"
    @complete="edit($event)"
  />
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import Form from '../Form/Form';
import { useStore } from 'vuex';
import {
  EDIT_SERVICE,
} from '@/store/types';

export default {
  components: {
    Form
  },
  setup() {
    const { i18n } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const breadcrumbItems = [
      { label: 'Service List', to: '/service/list' },
      { label: 'Update Service', to: route }
    ];

    const currentStep = ref(1);

    const stepItems = [
      {
        label: 'Information',
        name: 'ServiceEditStep1',
        query: {
          service: route.query.service
        },
        to: '/service/edit/step1'
      },
      {
        label: 'Questionnaire',
        name: 'ServiceEditStep2',
        query: {
          service: route.query.service
        },
        to: '/service/edit/step2'
      },
      {
        label: 'Calendar',
        name: 'ServiceEditStep3',
        query: {
          service: route.query.service
        },
        to: '/service/edit/step3'
      }
    ];

    const isLoading = ref(false);

    async function edit(e) {
      let isError = false;
      isLoading.value = true;

      try {
        await store.dispatch(EDIT_SERVICE, {id: route.query.service, payload: e});
        isLoading.value = false;
      } catch {
        isError = true;
      }
      if (!isError) router.push({ name: 'service-list' });
    }

    onMounted(() => {
      router.push({ name: 'ServiceEditStep1', query: {service: route.query.service}});
    });

    return {
      i18n,
      breadcrumbItems,
      currentStep,
      stepItems,
      isLoading,
      edit,
    };
  }
};
</script>

<style lang="scss" scoped>
.card {
  width: 100%;
}
.service-info {
  margin: 0 auto;
  width: 100%;
}
</style>